<script setup lang="ts">
const webStore = useWebsiteStore();
await callOnce(webStore.getMainData);
const i18nHead = useLocaleHead({})
const setScrollbarWidth = (delay = 1000) => {
    document.documentElement.style.setProperty('--scrollbar-width', (window.innerWidth - document.documentElement.clientWidth) + "px");

    setTimeout(() => {
        setScrollbarWidth(Math.min(delay * 1.5, 15000));
    }, delay);
}

onMounted(() => {
    if (process.client) {
        setScrollbarWidth();
    }
})

import 'core-js/proposals/change-array-by-copy';
</script>

<template>
    <NuxtLoadingIndicator />
    <NuxtLayout />
</template>

<style>
.page-enter-active,
.page-leave-active {
    transition: all 0.3s;
}

.page-enter-from {
    transform: translateX(-10px) translateY(-10px)
}

.page-enter-from,
.page-leave-to {
    opacity: 0;
    filter: blur(2px);
}

.page-leave-to {
    transform: translateX(10px) translateY(10px);
}
</style>